<template>
  <vca-card>
    <h2>{{ $t("taking.list.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <TakingTable />
    </vca-card>
  </vca-card>
</template>

<script>
import TakingTable from "@/components/finance/takings/AsyncTakingTable";
export default {
  name: "TakingsList",
  components: { TakingTable },
  created() {
    this.$store.commit("takings/pg/route", "finances/taking");
    this.$store.commit("takings/pg/context", "takings/pg");
    this.$store.commit("takings/pg/currentSort", "date_of_taking");
    this.$store.commit("takings/pg/currentSortDir", "desc");
  },
  computed: {
    current: {
      get() {
        return this.$store.state.takings.current;
      },
      set(value) {
        this.$store.commit("takings/current", value);
      },
    },
  },
};
</script>
